import React from 'react';
import SectionLayout from '../../../layouts/SectionLayout';
import mappingOverviewImg from '../../../images/cai_services_mapping.png';
import SEO from '../../../components/SEO';

export default function GisMappingServices() {
  return (
    <>
      <SEO title="Services - GIS Mapping" />
      <SectionLayout>
        <p className="section-display-subheader">
          DIGITAL TRANSFORMATION. NO ADDED WORK.
        </p>
        <div className="row mobile-desktop-text-margin">
          <div className="col-sm-12">
            <p>
              We understand not every AG operation will have complete digital
              records, which is why we provide GIS services to help transition
              your records to a digital environment that is easy to use. Our GIS
              staff can convert paper maps into digital map layers, scout
              in-field asset locations, or convert historical data.
            </p>
          </div>
        </div>

        <div className="row mobile-desktop-img-margin">
          <div className="col-sm-12 text-center">
            <img
              className="img-fluid"
              alt="gis mapping services overview img"
              src={mappingOverviewImg}
            />
          </div>
        </div>
      </SectionLayout>
    </>
  );
}
